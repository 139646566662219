<template>
  <div>
    <center>
      <h3 class="text-h4" style="margin-bottom: 30px">Logging in...</h3>
      <v-progress-circular
        :size="100"
        :width="10"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </center>
  </div>
</template>

<script>
import { tlMe } from "@/clients/tracelight.js";

export default {
  name: "Home",
  mounted() {
    tlMe().then((r) => console.log(r));
  },
  data: function () {
    return {};
  },
};
</script>
